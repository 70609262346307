import { usePageStore } from '@voix/store/pageStore'
import { useAdminStore } from '@voix/store/adminStore'
import { $voixNuxtApi } from '@voix/voix-layer/composables/voixNuxtApi'
import { useVoixToast } from '@voix/composables/useVoixToast'
import { createError } from '#imports'

interface CurrentPageArgs {
  path: string
  fetchPolicy?: string
}

// Will return a page or a redirect
// Contains .page and .redirect - one will be null
async function handleResponse(response: any) {
  const pageStore = usePageStore()
  pageStore.setPageResponse(response)

  setCurrentPageInStore(response.page)
}

function setCurrentPageInStore(pageData: any) {
  const pageStore = usePageStore()

  // Store the page data for use in the app
  pageStore.setCurrentPage(pageData)
}

async function getCurrentPage(variables: CurrentPageArgs) {
  const adminStore = useAdminStore()
  adminStore.isLoading = true

  const pageStore = usePageStore()

  pageStore.currentPath = variables.path

  let path = pageStore.currentPath
  if (!path.startsWith('/'))
    path = `/${path}`

  const { data, error } = await $voixNuxtApi(
    `/api/voix/responses${path}`,
  )

  if (error.value) {
    throw createError({
      statusCode: 503,
      statusMessage: 'API Service Unavailable',
      fatal: true,
    })
  }

  if (data.value)
    handleResponse(data.value)

  adminStore.isLoading = false

  return data
}

interface PageUpdateArgs {
  id: string
  site_id: string
  language_code: string
  path: string
  layout: string
  title: string
}

async function updateCurrentPage(variables: PageUpdateArgs) {
  const adminStore = useAdminStore()
  const voixToast = useVoixToast()
  adminStore.isLoading = true

  const request = await $voixNuxtApi(
    `/api/voix/pages/${variables.id}`,
    {
      method: 'PUT',
      body: variables,
    },
  )

  setCurrentPageInStore(request.data.value)

  adminStore.isLoading = false

  if (request.data.value) {
    voixToast.add(`Page Updated`, 'msg')

    return request
  }
  else {
    throw new Error('Unable to save')
  }
}

export { getCurrentPage, updateCurrentPage }
